
import bannerImage from "../../../public/index/banner.png"
import Link from "next/link";
import useMarketplaceChain from "../../../hooks/useMarketplaceChain";
import { Box, Flex, Text } from "../../primitives";
import { NextPage } from "next";
const Banner: NextPage = () => {
    const { routePrefix } = useMarketplaceChain()
    return (
        <>
            <Box css={{
                width: "100%",
                height: "100vh",
                background: `url(${bannerImage.src})  center`,
                backgroundSize: "cover",
                textAlign: "center",
                px: "$4"
            }}>
                <Flex justify="center" align="center" css={{
                    width: "100%",
                    height: "100%",
                    flexDirection: "column"
                }}>
                    <Text css={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        fontFamily: "Playfair Display",
                        textShadow: "10px 10px 100px rgba(0, 0, 0, 0.25)",
                        lineHeight: "1.3",
                        "@md": {
                            fontSize: "132px",
                            fontWeight: "bold"
                        }
                    }}>Temunft For Emby Games</Text>
                    <div style={{
                        fontWeight: "800",
                        marginTop: "100px"
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            backgroundColor: "black",
                            padding: "20px"
                        }}>
                            <span style={{
                                color: "#FFFFFF",
                                fontSize: '20px',
                            }}>Know More</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            backgroundColor: "black",
                            padding: "20px",
                            marginLeft: "30px"
                        }}>
                            <span style={{
                                color: "#FFFFFF",
                                fontSize: '20px',
                            }}>Explore</span>
                        </Link>
                    </div>
                </Flex>
            </Box>

        </>

    )
}

export default Banner